import { useParams } from "wouter";
import { HTTPMethods, UrlPaths } from "../../utilities/types";
import { useCallback, useEffect, useState } from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";
import useOneTrustData from "../../hooks/trustHooks/useOneTrustData";
import { TTrustsData } from "../../utilities/trustTypes";
import TrustForm from "./TrustForm";
import styled from "styled-components";
import DeleteTemplateModal from "../common/DeleteTemplateModal";
// import useGetCPTTemplateData from "../../hooks/templateHooks/useGetCPTTemplate";
// import useGetSPTTemplateData from "../../hooks/templateHooks/useGetSPTTemplate";
// import useGetTATemplateData from "../../hooks/templateHooks/useGetTATemplate";

const StyledActionButtons = styled.div`
  margin: 12px 18px 8px 18px;
  display: flex;
  justify-content: flex-end;

  .btn:last-of-type {
    margin-left: 8px;
  }
`;

export default function EditTrust() {
  const params = useParams();
  const { oneTrustData, trustsData, isLoading, error } = useOneTrustData();
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] =
    useState<boolean>(false);

  // const { fetchCPTTemplate, isLoadingCPTTemplate } = useGetCPTTemplateData();
  // const { fetchSPTTemplate, isLoadingSPTTemplate } = useGetSPTTemplateData();
  // const { fetchTATemplate, isLoadingTATemplate } = useGetTATemplateData();

  const trustID = params.id ?? "";

  // const handleGenerate = (
  //   trustType: string,
  //   trustId: string,
  //   trustName: string
  // ) => {
  //   if (trustType === TrustTypes.CPT) {
  //     fetchCPTTemplate(trustId, trustName);
  //   } else if (trustType === TrustTypes.SPT) {
  //     fetchSPTTemplate(trustId, trustName);
  //   } else if (trustType === TrustTypes.TA) {
  //     fetchTATemplate(trustId, trustName);
  //   } else {
  //     console.error("Trust Type not supported");
  //   }
  // };

  useEffect(() => {
    oneTrustData(trustID, HTTPMethods.GET);
  }, [oneTrustData, trustID]);

  const sendEditTrustData = useCallback(
    async (trustData: TTrustsData) => {
      await oneTrustData(trustID, HTTPMethods.PUT, trustData);
      oneTrustData(trustID, HTTPMethods.GET);
    },
    [oneTrustData, trustID]
  );

  const sendDeleteTrust = useCallback(() => {
    oneTrustData(trustID, HTTPMethods.DELETE);
    setIsDeleteConfirmVisible(false);
  }, [oneTrustData, trustID]);

  return (
    <Container fluid>
      <StyledActionButtons>
        <Button onClick={() => setIsDeleteConfirmVisible(true)}>Delete</Button>
        {/* <Button
          onClick={() =>
            handleGenerate(
              trustsData?.trust?.type || "",
              trustID,
              trustsData?.trust?.name || ""
            )
          }
          disabled={
            isLoadingCPTTemplate || isLoadingSPTTemplate || isLoadingTATemplate
          }
        >
          {isLoadingCPTTemplate || isLoadingSPTTemplate || isLoadingTATemplate
            ? "Generating..."
            : "Generate"}
        </Button> */}
        <Button href={UrlPaths.Trusts}>Back</Button>
      </StyledActionButtons>
      <Row className="m-3 justify-content-center">
        <TrustForm
          trustsData={trustsData}
          isLoading={isLoading}
          error={error}
          apiFunction={sendEditTrustData}
        />
      </Row>
      <Modal
        centered
        show={isDeleteConfirmVisible}
        onHide={() => setIsDeleteConfirmVisible(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
      >
        <DeleteTemplateModal
          trustName={trustsData?.trust?.name ?? " "}
          isLoading={isLoading}
          onDelete={sendDeleteTrust}
          onCancel={() => setIsDeleteConfirmVisible(false)}
        ></DeleteTemplateModal>
      </Modal>
    </Container>
  );
}
