import { Col, Form, Row } from "react-bootstrap";
import { TRealEstateData, TTrustsData } from "../../../../utilities/trustTypes";
import { getDefaultRealEstateData } from "../../../../utilities/defaultDataTypes";
import styled from "styled-components";

const StyledHeadlineH5 = styled.h5`
  color: #4c5637;
  font-weight: bold;
`;

const StyledSecondaryH5 = styled.h5`
  color: #656e51;
  font-weight: bold;
  font-size: 18px;
`;

const ReadOnlyField = styled(Form.Control)`
  background-color: #e0e0e0;
`;

export const defaultRealEstateData: TRealEstateData =
  getDefaultRealEstateData();

export default function CPTRealEstate({
  formData,
  setFormData,
  readOnly,
}: {
  formData: TTrustsData;
  setFormData: (trustsData: TTrustsData) => void;
  readOnly?: boolean;
}) {
  const updateCurrentRealEstate = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key: keyof TRealEstateData
  ) => {
    if (readOnly) return;
    let value: string | boolean = event.currentTarget.value;

    if (value === "true") {
      value = true;
    } else if (value === "false") {
      value = false;
    }
    const updatedRealEstate = {
      ...formData.realEstate,
      [key]: value,
    };

    setFormData({
      ...formData,
      realEstate: updatedRealEstate,
    });
  };
  return (
    <>
      <Row>
        <Col xs={8}>
          <Form.Group className="mb-3" controlId="fullAddress">
            <Form.Label>Full Address (READ ONLY)</Form.Label>
            <ReadOnlyField
              name="fullAddress"
              type="text"
              value={formData.realEstate?.fullAddress}
              onChange={(target) =>
                updateCurrentRealEstate(target, "fullAddress")
              }
              readOnly
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="street">
            <Form.Label>Street Address</Form.Label>
            <Form.Control
              name="street"
              type="text"
              value={formData.realEstate?.street}
              onChange={(target) => updateCurrentRealEstate(target, "street")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="city">
            <Form.Label>City</Form.Label>
            <Form.Control
              name="city"
              type="text"
              value={formData.realEstate?.city}
              onChange={(target) => updateCurrentRealEstate(target, "city")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="state">
            <Form.Label>State</Form.Label>
            <Form.Control
              name="state"
              type="text"
              value={formData.realEstate?.state}
              onChange={(target) => updateCurrentRealEstate(target, "state")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="zip">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              name="zip"
              type="text"
              value={formData.realEstate?.zip}
              onChange={(target) => updateCurrentRealEstate(target, "zip")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <Form.Group className="mb-3" controlId="county">
            <Form.Label>County</Form.Label>
            <Form.Control
              name="county"
              type="text"
              value={formData.realEstate?.county}
              onChange={(target) => updateCurrentRealEstate(target, "county")}
            />
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group className="mb-3" controlId="apn">
            <Form.Label>Property APN</Form.Label>
            <Form.Control
              name="apn"
              type="text"
              value={formData.realEstate?.apn}
              onChange={(target) => updateCurrentRealEstate(target, "apn")}
            />
          </Form.Group>
        </Col>
      </Row>
      <hr />
      <Row className="mb-3">
        <StyledHeadlineH5>Deed Information</StyledHeadlineH5>
      </Row>
      <Row className="mb-3">
        <StyledSecondaryH5>Grantors</StyledSecondaryH5>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="grantor1">
            <Form.Label>Name of Grantor 1 on Deed</Form.Label>
            <Form.Control
              name="grantor1"
              type="text"
              value={formData.realEstate?.grantor1}
              onChange={(target) => updateCurrentRealEstate(target, "grantor1")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="grantor2">
            <Form.Label>Name of Grantor 2 on Deed</Form.Label>
            <Form.Control
              name="grantor2"
              type="text"
              value={formData.realEstate?.grantor2}
              onChange={(target) => updateCurrentRealEstate(target, "grantor2")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="grantorTitle">
            <Form.Label>Title of Grantors on Deed</Form.Label>
            <Form.Control
              name="grantorTitle"
              as="textarea"
              value={formData.realEstate?.grantorTitle}
              onChange={(target) =>
                updateCurrentRealEstate(target, "grantorTitle")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <StyledSecondaryH5>Property</StyledSecondaryH5>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Property Description</Form.Label>
            <Form.Control
              name="description"
              type="text"
              value={formData.realEstate?.description}
              onChange={(target) =>
                updateCurrentRealEstate(target, "description")
              }
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
