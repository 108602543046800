import { Col, Form, Row } from "react-bootstrap";
import { TTrustData, TTrustsData } from "../../../../utilities/trustTypes";
import { getDefaultTrustData } from "../../../../utilities/defaultDataTypes";
import styled from "styled-components";

const StyledHeadlineH5 = styled.h5`
  color: #4c5637;
  font-weight: bold;
`;

export const defaultTrustData: TTrustData = getDefaultTrustData();

export default function TATrust({
  formData,
  setFormData,
  readOnly,
}: {
  formData: TTrustsData;
  setFormData: (trustsData: TTrustsData) => void;
  readOnly?: boolean;
}) {
  const updateCurrentTrust = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key: keyof TTrustData
  ) => {
    let value: string | boolean = event.currentTarget.value;

    if (value === "true") {
      value = true;
    } else if (value === "false") {
      value = false;
    }

    if (!formData.trust) return;

    const updatedTrust = {
      ...formData.trust,
      [key]: value,
    };

    setFormData({
      ...formData,
      trust: updatedTrust,
    });
  };

  return (
    <>
      <Row>
        <Col xs={7}>
          <Form.Group className="mb-3" controlId="newTrustName">
            <Form.Label>Trust Name</Form.Label>
            <Form.Control
              name="name"
              type="text"
              value={formData.trust?.name}
              onChange={(target) => updateCurrentTrust(target, "name")}
            />
          </Form.Group>
        </Col>
        <Col xs={5}>
          <Form.Group className="mb-3" controlId="newTrustAKA">
            <Form.Label>Trust AKA</Form.Label>
            <Form.Control
              name="nameAKA"
              type="text"
              value={formData.trust?.trustAka}
              onChange={(target) => updateCurrentTrust(target, "trustAka")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <Form.Group className="mb-3" controlId="newTrustDate">
            <Form.Label>Trust Date</Form.Label>
            <Form.Control
              name="date"
              type="date"
              value={formData.trust?.date}
              onChange={(target) => updateCurrentTrust(target, "date")}
            />
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group className="mb-3" controlId="meetingDate">
            <Form.Label>Date of TA Meeting</Form.Label>
            <Form.Control
              name="year"
              type="date"
              value={formData.trust?.year}
              onChange={(target) => updateCurrentTrust(target, "year")}
            />
          </Form.Group>
        </Col>
        {/* <Col className="col-auto">
          <Form.Group controlId="newTrustType">
            <Form.Label className="mx-2">Trust Type</Form.Label>
            <Form.Group className="mb-3">
              <Form.Check
                inline
                name="type"
                type="radio"
                label="CPT"
                value="CPT"
                checked={formData.trust?.type === "CPT"}
                onChange={(target) => updateCurrentTrust(target, "type")}
              ></Form.Check>
              <Form.Check
                inline
                name="type"
                type="radio"
                label="SPT"
                value="SPT"
                checked={formData.trust?.type === "SPT"}
                onChange={(target) => updateCurrentTrust(target, "type")}
              ></Form.Check>
              <Form.Check
                inline
                name="type"
                type="radio"
                label="TA"
                value="TA"
                checked={formData.trust?.type === "TA"}
                onChange={(target) => updateCurrentTrust(target, "type")}
              ></Form.Check>
            </Form.Group>
          </Form.Group>
        </Col> */}
      </Row>
      <hr />
      <Row className="mb-3">
        <StyledHeadlineH5>Heirs</StyledHeadlineH5>
      </Row>
      <Row>
        <Col xs={2}>
          <Form.Group className="mb-3" controlId="numHeirs">
            <Form.Label>Number of Heirs</Form.Label>
            <Form.Control
              name="numHeirs"
              type="number"
              value={formData.trust?.numHeirs}
              onChange={(target) => updateCurrentTrust(target, "numHeirs")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs lg="10">
          <Form.Group className="mb-3" controlId="heir1">
            <Form.Label>Heir 1 Name</Form.Label>
            <Form.Control
              name="heir1"
              as="textarea"
              value={formData.trust?.heir1}
              onChange={(target) => updateCurrentTrust(target, "heir1")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs lg="10">
          <Form.Group className="mb-3" controlId="heir2">
            <Form.Label>Heir 2 Name</Form.Label>
            <Form.Control
              name="heir2"
              as="textarea"
              value={formData.trust?.heir2}
              onChange={(target) => updateCurrentTrust(target, "heir2")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs lg="10">
          <Form.Group className="mb-3" controlId="heir3">
            <Form.Label>Heir 3 Name</Form.Label>
            <Form.Control
              name="heir3"
              as="textarea"
              value={formData.trust?.heir3}
              onChange={(target) => updateCurrentTrust(target, "heir3")}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
