import { useState } from "react";
import { headers } from "../authHeader";

export type TTemplateData = {
  value: string;
  name: string;
  isSelected: boolean;
}[];

export default function usePostBatchTemplatesData() {
  const [isLoadingBatchTemplates, setIsLoadingBatchTemplates] =
    useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchBatchTemplates = async (
    textData: TTemplateData,
    trustId: string,
    trustName: string,
    hasInitials: boolean
  ) => {
    const createSelectionObject = (data: TTemplateData) => {
      return data.reduce((accumulator, current) => {
        accumulator[current.value] = current.isSelected;
        return accumulator;
      }, {} as Record<string, boolean>);
    };

    const selectionObject = createSelectionObject(textData);

    const url = `https://hometrustapi.azurewebsites.net/api/templates/generate?trustId=${trustId}&vialOfLife=${selectionObject.vialOfLife}&binderBasics=${selectionObject.binderBasics}&averyLabels=${selectionObject.averyLabels}&cpt=${selectionObject.cpt}&spt=${selectionObject.spt}&ta=${selectionObject.ta}&trustCertificate=${selectionObject.trustCertificate}&trustTransferDeed=${selectionObject.trustTransferDeed}&loveLetter=${selectionObject.loveLetter}&initials=${hasInitials}`;

    setIsLoadingBatchTemplates(true);
    setError(null);

    try {
      await fetch(url, {
        headers,
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${trustName} Templates.zip`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch((error) => console.error("Error downloading file:", error));
    } catch (error: any) {
      setError(error);
      setIsLoadingBatchTemplates(false);
    }
  };

  return { fetchBatchTemplates, isLoadingBatchTemplates, error };
}
