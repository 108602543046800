import { useCallback, useState } from "react";
import { headers } from "../authHeader";

export default function useGetCPTTemplateData() {
  const [isLoadingCPTTemplate, setIsLoadingCPTTemplate] =
    useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchCPTTemplate = useCallback(
    async (trustId: string, trustName: string) => {
      let url = `https://hometrustapi.azurewebsites.net/api/Templates/generate/CPT/${trustId}`;

      setIsLoadingCPTTemplate(true);
      setError(null);
      console.log(trustName);

      console.log("getting Templates");

      const sanitizedFileName = (fileName: string) => {
        return fileName.trim().replace(/[/\\:*?"<>|]/g, "_");
      };

      const downloadBlob = (blob: Blob, fileName: string) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      };

      try {
        const response = await fetch(url, { headers, method: "GET" });

        if (!response.ok) {
          throw new Error(`Failed to download document ${response.statusText}`);
        }

        const blob = await response.blob();

        const downloadFileName = `${sanitizedFileName(trustName)}_CPT.docx`;
        console.log(downloadFileName);

        downloadBlob(blob, downloadFileName);
      } catch (error: any) {
        console.error("Error downloading file", error);
        setError(error);
      } finally {
        setIsLoadingCPTTemplate(false);
      }
    },
    []
  );

  return { isLoadingCPTTemplate, error, fetchCPTTemplate };
}
