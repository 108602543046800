import { Button, Row, Container } from "react-bootstrap";
import { TrustTypes, UrlPaths } from "../../utilities/types";
import { TTrustsData } from "../../utilities/trustTypes";
import TrustForm from "./TrustForm";
import { useState } from "react";
import styled from "styled-components";
import useGetCPTTemplateData from "../../hooks/templateHooks/useGetCPTTemplate";
import useGetSPTTemplateData from "../../hooks/templateHooks/useGetSPTTemplate";
import useGetTATemplateData from "../../hooks/templateHooks/useGetTATemplate";

const StyledActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 12px 18px 8px 18px;

  .btn:nth-child(1) {
    margin-right: 8px;
  }
  .btn:nth-child(2) {
    margin-right: 8px;
  }
`;

export default function TrustInfoTab({
  trustID,
  trustsData,
  error,
}: {
  trustID: string;
  trustsData: TTrustsData | null;
  error: any;
}) {
  const [isReadOnly, setIsReadOnly] = useState(true);
  const { fetchCPTTemplate, isLoadingCPTTemplate } = useGetCPTTemplateData();
  const { fetchSPTTemplate, isLoadingSPTTemplate } = useGetSPTTemplateData();
  const { fetchTATemplate, isLoadingTATemplate } = useGetTATemplateData();

  const toggleEditMode = () => setIsReadOnly((prev) => !prev);

  const handleGenerate = (
    trustType: string,
    trustId: string,
    trustName: string
  ) => {
    if (trustType === TrustTypes.CPT) {
      fetchCPTTemplate(trustId, trustName);
    } else if (trustType === TrustTypes.SPT) {
      fetchSPTTemplate(trustId, trustName);
    } else if (trustType === TrustTypes.TA) {
      fetchTATemplate(trustId, trustName);
    } else {
      console.error("Trust Type not supported");
    }
  };

  return (
    <Container fluid>
      <StyledActionButtons>
        <Button
          onClick={() =>
            handleGenerate(
              trustsData?.trust?.type || "",
              trustID,
              trustsData?.trust?.name || ""
            )
          }
          disabled={
            isLoadingCPTTemplate || isLoadingSPTTemplate || isLoadingTATemplate
          }
        >
          {isLoadingCPTTemplate || isLoadingSPTTemplate || isLoadingTATemplate
            ? "Generating..."
            : "Generate"}
        </Button>
        <Button href={UrlPaths.EditTrust + trustID}>Edit</Button>
        <Button onClick={toggleEditMode} href={UrlPaths.Trusts}>
          Back
        </Button>
      </StyledActionButtons>
      <Row className="m-3 justify-content-center">
        <TrustForm
          trustsData={trustsData}
          isLoading={false}
          error={error}
          readOnly={isReadOnly}
        />
      </Row>
    </Container>
  );
}
