import { Button, Modal, Spinner } from "react-bootstrap";
import styled from "styled-components";

const StyledSpan = styled.span`
  font-weight: bold;
`;

const StyledParagrah = styled.p`
  text-align: center;
`;

export default function DeleteConfirmation({
  trustName,
  isLoading,
  onDelete,
  onCancel,
}: {
  trustName: string;
  isLoading: boolean;
  onDelete: () => void;
  onCancel: () => void;
}) {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StyledParagrah>
          Are you sure you want to delete <StyledSpan>{trustName}</StyledSpan>?
        </StyledParagrah>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button href={UrlPaths.EditTrust + `${trustID}`}>Cancel</Button> */}
        <Button onClick={onCancel}>Cancel</Button>

        <Button variant="danger" disabled={isLoading} onClick={onDelete}>
          {isLoading ? (
            <Spinner as="span" animation="border" size="sm" />
          ) : (
            "Delete"
          )}
        </Button>
      </Modal.Footer>
    </>
  );
}
